import React from 'react'
import PropTypes from 'prop-types'
import {Image, Card, Segment, Header} from 'semantic-ui-react'
import cdfa from '../../images/logo_cdfa.png'
import AMMPTool from '../../images/tool-ammp.jpg'
import HSPTool from '../../images/tool-hsp.jpg'

class Overview extends React.Component {
  state = {
    started: false,
  }

  render() {
    return (
      <div id="OverviewContainer" style={{display: this.props.isVisible ? '' : 'none', padding: '3em'}}>
        <Segment className="overview" basic style={{margin: 'auto'}}>
          <Header textAlign="center" as="h1">RePlan</Header>
          <p>
            RePlan is a core component of the California Strategic Growth Council’s (SGC) Integrated Regional
            Conservation and Development (IRCAD) initiative.
          </p>
          <Header textAlign="center" as="h2">California Department of Food and Agriculture Extensions</Header>
          <Segment basic style={{display: 'flex'}}>
            <p>
              <Image src={cdfa} size="small" floated="left" style={{height: 'fit-content', margin: '0 36px 18px 0'}} />
              The CDFA Office of Environmental Farming and Innovation houses a number of Climate Smart Agriculture
              grant programs. These RePlan extensions provide easy access to the spatially explicit data that helps
              inform the Healthy Soils Program (HSP), and tools for prospective Alternative Manure Management Program
              (AMMP) grant applicants to explore alternative manure management practices and potential GHG emission
              reductions, and develop proposed project diagrams.
            </p>
          </Segment>
          <Card.Group itemsPerRow={2} doubling>
            <Card href="/cdfa/" raised centered>
              <Image
                src={HSPTool}
                alt="Healthy Soils Program web application"
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header>HSP Tool</Card.Header>
                <Card.Meta>
                  <span className='date'>RePlan CDFA Extension</span>
                </Card.Meta>
                <Card.Description>
                  The Healthy Soils Program (HSP) provides financial incentives to California growers and ranchers to
                  implement conservation management practices that help sequester carbon, reduce atmospheric greenhouse
                  gases, and improve overall soil health.
                </Card.Description>
              </Card.Content>
            </Card>
            <Card href="/ammp/" raised centered>
              <Image
                src={AMMPTool}
                alt="Alternative Manure Management Program web application"
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header>AMMP Tool</Card.Header>
                <Card.Meta>
                  <span className='date'>RePlan CDFA Extension</span>
                </Card.Meta>
                <Card.Description>
                  The Alternative Manure Management Program (AMMP) provides financial assistance for the implementation
                  of non-digester manure management practices on California dairy and livestock operations, which will
                  result in reduced greenhouse gas emissions.
                </Card.Description>
              </Card.Content>
            </Card>
          </Card.Group>
        </Segment>
      </div>
    )
  }
}

Overview.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  hideOverview: PropTypes.func.isRequired,
}

export default Overview
